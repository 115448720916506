import React from 'react'
import Layout from 'layouts/fr'
import Template from 'templates/privacy-policy'
import { graphql } from 'gatsby'

export default (props) => {
  let html = ''
  try {
    console.log('privacy', props)
    html = props.data.allMarkdownRemark.edges[0].node.html
  } catch(e) {}

  return (
    <Layout p={5}>
      <Template lang="en" content={<div dangerouslySetInnerHTML={{ __html: html }} />} />
    </Layout>
  )
}

export const query = graphql`
  query pp_fr {
  allMarkdownRemark(filter: { frontmatter: { title: { eq: "en/privacy" } } }) {
    edges {
      node {
        id,
        frontmatter {
          title
        }
        html
      }
    }
  }
}
`;